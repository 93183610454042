import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_SELECTED_COUNTRIES } from 'constants/defaults';
import { SupportedCountriesState } from './types/supportedCountries.types';

const initialState: SupportedCountriesState = {
  supportedCountries: DEFAULT_SELECTED_COUNTRIES,
  error: '',
  isFetchingSupportedCountries: false,
};

export const supportedCountriesSlice = createSlice({
  name: 'supportedCountries',
  initialState,
  reducers: {
    fetchSupportedCountriesStart: (state) => {
      state.isFetchingSupportedCountries = true;
      state.error = '';
    },
    fetchSupportedCountriesSuccess: (state, action: PayloadAction<string[]>) => {
      state.supportedCountries = action.payload;
      state.isFetchingSupportedCountries = false;
    },
    fetchSupportedCountriesFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isFetchingSupportedCountries = false;
    },
    setSupportedCountries: (state, action: PayloadAction<string[]>) => {
      state.supportedCountries = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchSupportedCountriesStart,
  fetchSupportedCountriesSuccess,
  fetchSupportedCountriesFailure,
  setSupportedCountries,
  setError,
} = supportedCountriesSlice.actions;

export default supportedCountriesSlice.reducer;
