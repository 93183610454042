import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useEnvContext } from 'utils/EnvProvider';
import { getAdminPortalSharedBff } from 'services/getAdminPortalSharedBff';
import {
  fetchSupportedCountriesStart,
  fetchSupportedCountriesSuccess,
  setSupportedCountries,
} from 'store/slices/supportedCountries.slice';

const RETRY_DELAY = 2000; // 2 seconds delay
const MAX_RETRY = 3;

const useUserMetadata = () => {
  const dispatch = useDispatch();
  const { api } = getAdminPortalSharedBff();
  const { REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF } = useEnvContext();

  const getSupportedCountries = useCallback(
    async (retryCount = 0) => {
      const token = localStorage.getItem('authHeader');
      dispatch(fetchSupportedCountriesStart());
      try {
        const response = await api.get('/user-metadata', {
          headers: {
            Authorization: token,
            requestTraceId: REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF,
          },
        });
        const { supportedCountries } = response?.data || [];

        dispatch(setSupportedCountries(supportedCountries));
        dispatch(fetchSupportedCountriesSuccess(supportedCountries));
      } catch (error) {
        if (retryCount < MAX_RETRY && error.response?.status === 401) {
          setTimeout(() => getSupportedCountries(retryCount + 1), RETRY_DELAY);
        }
      }
    },
    [api, dispatch, REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF]
  );

  return { getSupportedCountries };
};

export default useUserMetadata;
